import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";

interface FaqItemProps
{
    question : string, 
    answer : string
}

export class FaqItem extends React.Component<FaqItemProps>
{
    state = { isAnswerVisible : false }

    render(): React.ReactNode {

        let isMobile = Util.isMobileDevice();

        let spacing = isMobile ? 16 : 32;

        let offsetY = Util.getContentOffset();

        let containerStyle : CSSProperties =
        {
            width: "100%",
            boxSizing: "border-box",
            marginBottom: spacing,
            transform: `translate(0px, ${offsetY}px)`
        }

        let cardStyle : CSSProperties =
        {
            fontSize: "17px",
            textAlign: "left",
            color: Colors.lightGrey,
            fontFamily: "Open Sans, sans-serif",
            padding: 0,//padding,
            border: Styles.border,
            borderRadius: Styles.borderRadius,
            background: Colors.darkerGrey,
            maxWidth: 1000,
            marginLeft: "auto",
            marginRight: "auto",
            cursor: "pointer"
        }

        let questionTextStyle : CSSProperties =
        {
            margin: 0,
            alignSelf: "center",
            fontWeight: "bold",
            fontSize: "18px"
        }

        let answerTextStyle : CSSProperties =
        {
            margin: 0
        }

        let answerStyle : CSSProperties =
        {
            display: this.state.isAnswerVisible ? "block" : "none",
            margin: spacing
        }

        return (
            <div style={containerStyle}>
                <div style={cardStyle} onClick={() => this.openClose()}>
                    <div style={{ display: "flex", flexDirection: "row", margin: spacing }}  >
                        <p style={questionTextStyle}>{this.props.question}</p>
                        <img style={{ marginLeft: "auto", alignSelf: "center" }} width={32} height={32} src={Util.getImageUrl(this.state.isAnswerVisible ? "icon-minus.svg" : "icon-plus.svg")} />
                    </div>
                    <div style={answerStyle}>
                        <p style={answerTextStyle}>{this.props.answer}</p>
                    </div>
                </div>
            </div>
        )
    }

    openClose()
    {
        this.setState({ isAnswerVisible: !this.state.isAnswerVisible });
    }
}