import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { EventbriteEvent, EventbriteEventsResponse } from "./EventbriteModels";
import { Button } from "@mui/material";

interface LocationHubCardProps
{
    locations : string[],
    offsetY : number
}

export class LocationHubCard extends React.Component<LocationHubCardProps>
{
    render()
    {
        let isMobile = Util.isMobileDevice();

        let spacing = isMobile ? 16 : 32;

        let headingStyle : CSSProperties =
        {
            margin: `${spacing}px ${spacing}px ${spacing*0.5}px ${spacing}px`
        }

        let subtitleStyle : CSSProperties =
        {
            margin: `0 ${spacing}px ${spacing}px ${spacing}px`
        }

        let linkStyleBlue : CSSProperties =
        {
            display: "inline-block",
            background: Colors.tablesBlue,
            color: Colors.veryDarkGrey,
            fontWeight: "bolder",
            margin: `0 0 ${spacing * 0.5}px ${spacing * 0.5}px`,
            padding: 16
        }

        let linkContainerStyle : CSSProperties =
        {
            margin: `0 ${spacing * 0.5}px ${spacing * 0.5}px ${spacing * 0.5}px`
        }

        let cardStyle : CSSProperties =
        {
            position: "relative",
            fontSize: "17px",
            textAlign: "left",
            color: Colors.lightGrey,
            fontFamily: "Open Sans, sans-serif",
            padding: 0,
            marginBottom: 32,
            transform: `translate(0px, ${this.props.offsetY}px)`,
            border: Styles.border,
            borderRadius: Styles.borderRadius,
            background: Colors.darkerGrey,
            maxWidth: 1000
        }

        let locationNamesFormatted = this.props.locations.map((location, key) => Util.getLocationNameFormatted(location));

        return (
            <div style={cardStyle}>
                <h1 style={headingStyle}>Upcoming Events By Area</h1>
                <p style={subtitleStyle}>Explore speed dating events in your area, hosted by <b>Tables Speed Dating:</b></p>
                <div style={linkContainerStyle}>
                    {this.props.locations.map((locaton, key) => 
                    <a key={key} href={`/speed-dating-events/${locaton}`}>
                        <div style={linkStyleBlue}>
                            {locationNamesFormatted[key]}
                        </div>
                    </a>)}
                </div>
                <p style={subtitleStyle}>Select a location to view upcoming speed dating events nearby.</p>
                <p style={subtitleStyle}>Speed dating is a unique microcosm of life's broader experiences. It's about embracing the present, making the most of each fleeting interaction. Philosophically, it mirrors life's impermanence, teaching us to value each moment and the potential it holds. Approach each conversation not as a means to an end but as a self-contained experience, rich with possibility.</p>
            </div>
        )
    }
}