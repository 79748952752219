import { CSSProperties } from "react";
import React from "react";
import { ReactNode } from "react";
import App from "./App";
import { Colors } from "./Colors";
import { Util } from "./Util";

interface ImageViewerProps
{
    imageUrl : string
}

export class ImageViewer extends React.Component<ImageViewerProps>
{
    render() {

        //remove #t=0.2 from videos
        let imageUrl = this.props.imageUrl.split("#")[0]; 
        
        let isWebm = imageUrl.endsWith(".webm");

        let isQrCode = imageUrl.endsWith("qr-download.png");

        let isMobile = Util.isMobileDevice();

        let [screenWidth, screenHeight] = Util.getScreenSize();

        let containerStyle : CSSProperties = 
        {
            position: "fixed",
            zIndex: 11,
            width: "100%",
            height: "100vh",
            background: Colors.colorWithAlpha(Colors.black, 0.9),
            cursor: "zoom-out",
            userSelect: "none",
            padding: isQrCode && !isMobile ? screenHeight - 800 : 32,
            boxSizing: "border-box"
        }

        let imgStyle : CSSProperties = 
        {
            height: "100%",
            width: "100%",
            display: "block",
            objectFit: "contain"
        }
            
        let videoContent = <video autoPlay loop style={imgStyle} src={imageUrl}/>;

        let imageContent = <img style={imgStyle} src={imageUrl}/>;

        return (
            imageUrl == "" ? "" :
            <div style={containerStyle} onClick={() => this.clicked()}>
                {isWebm ? videoContent : imageContent}
            </div>) 
    }

    clicked()
    {
        App.instance.setState({ selectedImageUrl: "" });
    }
}