import React, { CSSProperties, createRef } from "react";
import { Colors } from "../Colors";
import { Util } from "../Util";
import { ImageViewer } from "../ImageViewer";
import { StaticBackground } from "../StaticBackground";
import { NavBar } from "../NavBar";
import { HomeSection } from "../HomeSection";
import { AboutSection } from "../AboutSection";
import { ScreenshotsSection } from "../ScreenshotsSection";
import { FaqSection } from "../FaqSection";
import { Footer } from "../Footer";
import { Helmet } from "react-helmet";

interface HomePageProps
{
    selectedImageUrl : string,
    anchors : React.RefObject<HTMLAnchorElement>[]
}

export class HomePage extends React.Component<HomePageProps>
{

    render()
    {
        let isMobile = Util.isMobileDevice();

        let offsetY = Util.getContentOffset();

        let pageStyle : CSSProperties = 
        {
            width: "100%",
            overflowX: "hidden",
            fontFamily: 'Open Sans, sans-serif',
            colorScheme: "dark",
            WebkitTapHighlightColor: Colors.clear, //disables highlight when tapping things on Chrome & Opera on Android
        }

        /*
        Text content is required in the anchors to make them render in the correct position on iOS
        float: left and width: 0 are used to ensure the anchor doesn't take up space
        overflowX: hidden is used to ensure the text content is not visible
        */
        let anchorStyle : CSSProperties = 
        { 
            position: "relative", 
            float: "left", 
            width: 0, 
            overflowX: "hidden",
            top: offsetY//isMobile ? offsetY - 74 : offsetY - 58 
        }

        let eventsAnchorStyle = Util.copy(anchorStyle);
        eventsAnchorStyle.top -= isMobile ? 95 : 95;

        let aboutAnchorStyle = Util.copy(anchorStyle);
        aboutAnchorStyle.top -= isMobile ? 95 : 95;

        let galleryAnchorStyle = Util.copy(anchorStyle);
        galleryAnchorStyle.top -= isMobile ? 112 : 128;

        let faqAnchorStyle = Util.copy(anchorStyle);
        faqAnchorStyle.top -= isMobile ? 100 : 100;

        let fullUrl = "https://tablesapp.co.uk";

        let title = "Tables Speed Dating - Events in Cheshire and The North West";

        let description = `A fresh and fun way to meet new people. Discover meaningful connections at Tables Speed Dating events in Cheshire and The North West`;
    
        return (
            <div style={pageStyle}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <link rel="canonical" href={fullUrl}></link>
                </Helmet>
                <ImageViewer imageUrl={this.props.selectedImageUrl}/>
                <StaticBackground backgroundImages={[ Util.getImageUrl("bg-image1.jpg") ]}/>
                <NavBar location="all"/>
                <a id="0" style={anchorStyle} ref={this.props.anchors[0]}>home anchor</a>
                <HomeSection backgroundImages={[ Util.getImageUrl("bg-image3.jpg"), Util.getImageUrl("bg-image4.jpg") ]} applyFilter={true}/>
                <a id="1" style={eventsAnchorStyle} ref={this.props.anchors[1]}>events anchor</a>
                <AboutSection aboutAnchor={<a id="2" style={aboutAnchorStyle} ref={this.props.anchors[2]}>about anchor</a>}/>
                <a id="3" style={galleryAnchorStyle} ref={this.props.anchors[3]}>gallery anchor</a>
                <ScreenshotsSection/>
                <a id="4" style={faqAnchorStyle} ref={this.props.anchors[4]}>faq anchor</a>
                <FaqSection/>
                <Footer showTablesLogo={true}/>
            </div>
        );
    }
}