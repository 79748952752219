import { CSSProperties } from "react";
import { Colors } from "../Colors";
import { Util } from "../Util";
import { ImageViewer } from "../ImageViewer";
import { StaticBackground } from "../StaticBackground";
import { NavBar } from "../NavBar";
import { HomeSection } from "../HomeSection";
import { Footer } from "../Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ErrorCard } from "../ErrorCard";

interface ErrorPageProps
{
    selectedImageUrl : string
}

export const ErrorPage = (props : ErrorPageProps) => {

    let offsetY = Util.getContentOffset();

    let pageStyle : CSSProperties = 
    {
        width: "100%",
        overflowX: "hidden",
        fontFamily: 'Open Sans, sans-serif',
        colorScheme: "dark",
        WebkitTapHighlightColor: Colors.clear, //disables highlight when tapping things on Chrome & Opera on Android
    }

    let flexBoxContainer : CSSProperties =
    {
        display: "flex",          /* establish flex container */
        flexDirection: "column",  /* make main axis vertical */
        justifyContent: "center", /* center items vertically, in this case */
        alignItems: "center",     /* center items horizontally, in this case */
        height: "100%"
    }

    let flexBoxItem : CSSProperties =
    {
        margin: 0,
        textAlign: "center"       /* will center text in <p>, which is not a flex item */
    }

    let bgContainerStyle : CSSProperties =
    {
        position: "absolute",
        left: 0,
        right: 0,
    }

    return (
        <div style={pageStyle}>
            <Helmet>
                <title>Tables Speed Dating - 404</title>
                <meta name="description" content={`A fresh and fun way to meet new people. Discover meaningful connections at Tables Speed Dating events in Cheshire and The North West`} />
                <meta name="robots" content="noindex"/>
            </Helmet>
            <ImageViewer imageUrl={props.selectedImageUrl}/>
            <StaticBackground backgroundImages={[ Util.getImageUrl("bg-image1.jpg") ]}/>
            <NavBar location={"Speed Dating"}/>
            <HomeSection backgroundImages={[ ]} applyFilter={false}/>
            <div style={bgContainerStyle}>
            </div>
            <div style={flexBoxContainer}>
                <div style={flexBoxItem}>
                    <ErrorCard offsetY={offsetY}/>
                </div>
            </div>
            <Footer showTablesLogo={true}/>
        </div>
    );
}