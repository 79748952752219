import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { Card, Button, AppBar, Paper } from '@mui/material';

interface NavBarProps
{
    location : string
}

export class NavBar extends React.Component<NavBarProps>
{
    render(): React.ReactNode {

        let containerStyle : CSSProperties =
        {
            position: "fixed",
            width: "100%",
            height: Util.getNavbarHeight(),
            background: Colors.darkGrey,
            zIndex: 10
        }

        let logoContainerStyle : CSSProperties =
        {
            position: "absolute",
            left: "auto",
            right: "auto",
            width: "100%",
            maxWidth: 1000,
            height: "96px",
            //background: Colors.tablesPink,
            zIndex: 10
        }

        let flexBoxContainerStyle = Styles.flexBoxContainer;
        flexBoxContainerStyle.justifyContent = "end";

        let buttonStyle : CSSProperties =
        {
            color: Colors.midGrey,
            borderBottom: "4px solid " + Colors.midGrey,
            borderRadius: 0,
            margin: 0,
            fontFamily: Styles.defaultFont,
            fontWeight: 700
        }

        let activeButtonStyle = Util.copy(buttonStyle);
        activeButtonStyle.borderBottom = "4px solid " + Colors.tablesBlue;
        activeButtonStyle.color = Colors.white;

        let buttons = this.props.location === "all" ?
        <div style={Styles.flexBoxItem}>
            <Button href="#0" style={this.buttonIsActive(0) ? activeButtonStyle : buttonStyle}>Home</Button>
            <Button href="#1" style={this.buttonIsActive(1) ? activeButtonStyle : buttonStyle}>Events</Button>
            <Button href="#2" style={this.buttonIsActive(2) ? activeButtonStyle : buttonStyle}>About</Button>
            <Button href="#3" style={this.buttonIsActive(3) ? activeButtonStyle : buttonStyle}>Gallery</Button>
            <Button href="#4" style={this.buttonIsActive(4) ? activeButtonStyle : buttonStyle}>FAQ</Button>
        </div>
        :
        <div style={Styles.flexBoxItem}>
            <Button href="/" style={buttonStyle}>Home</Button>
            <Button href="" style={activeButtonStyle}>{Util.getLocationNameFormatted(this.props.location)} Events</Button>
        </div>

        return (
            <div style={containerStyle}>
                <div style={flexBoxContainerStyle}>
                    {buttons}
                </div>

                {/* Logo Container 
                <div style={{ position: "relative" }}>
                    <div style={{...flexBoxContainerStyle, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div style={logoContainerStyle}>
                            <div style={{...Styles.flexBoxContainer, alignItems: "flex-start" }}>
                                <div style={Styles.flexBoxItem}>
                                    <img style={{ width: 64, height: 64, marginLeft: 24 }} src={Util.getImageUrl("icon-tables-logo.svg")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        )
    }

    componentDidMount()
    {
        window.onscroll = () => this.handleScroll();
    }

    handleScroll()
    {
        this.setState({ });
    }

    buttonIsActive(anchorIndex : number)
    {
        let activeAnchor = Util.getActiveAnchor();

        return activeAnchor === anchorIndex;
    }
}