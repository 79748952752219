import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Faq } from "./Faq";
import { FaqItem } from "./FaqItem";
import { Styles } from "./Styles";
import { Util } from "./Util";

export class FaqSection extends React.Component
{
    render(): React.ReactNode {

        let isMobile = Util.isMobileDevice();

        let containerStyle : CSSProperties =
        {
            width: "100%",
            boxSizing: "border-box",
            padding: isMobile ? "16px 0" : "32px"
        }

        let faq = Faq.getFaq();
        let faqs = faq.map((faqItem, key) => <FaqItem key={key} question={faqItem[0]} answer={faqItem[1]}/>);

        return (
            <div style={containerStyle}>
                {faqs}
            </div>
        )
    }
}