import React, { createRef, CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import Carousel from "./Carousel";
import SmoothTransitionImage from "./SmoothTransitionImage";

interface HomeSectionProps 
{ 
    backgroundImages : string[],
    applyFilter : boolean
}

interface HomeSectionState
{
    dummyTextSize : number,
    bestTitleTextSize : number,
    titleTextSizeIsSet : boolean
}

export class HomeSection extends React.Component<HomeSectionProps, HomeSectionState>
{
    dummyTextContainer = createRef<HTMLDivElement>();

    state = { dummyTextSize: 1, bestTitleTextSize: 36, titleTextSizeIsSet: false };

    render(): React.ReactNode {

        let isMobile = Util.isMobileDevice();

        let padding = isMobile ? 16 : 32;

        let [currentScreenWidth, currentScreenHeight] = Util.getScreenSize();
        
        let notEnoughHeight = currentScreenHeight < 500;

        let sectionHeight = notEnoughHeight ? Util.initialScreenHeight * 2 : Util.initialScreenHeight;

        let containerStyle : CSSProperties =
        {
            width: "100%",
            height: sectionHeight,
            overflowY: "hidden"
        }

        let flexBoxContainer : CSSProperties =
        {
            display: "flex",          /* establish flex container */
            flexDirection: "column",  /* make main axis vertical */
            justifyContent: "center", /* center items vertically, in this case */
            alignItems: "center",     /* center items horizontally, in this case */
            height: "100%",
            paddingBottom: -Util.getContentOffset() - 96,
            boxSizing: "border-box",
            background: Colors.clear + " important!"
        }

        let flexBoxItem : CSSProperties =
        {
            margin: padding,
            textAlign: "left",
        }

        let titleStyle : CSSProperties =
        {
            fontSize: isMobile ? this.state.bestTitleTextSize + "px" : "64px",
            textSizeAdjust: "200%",
            color: Colors.lightGrey,
            fontFamily: Styles.defaultFont,
            margin: 0
        }

        let subtitleStyle : CSSProperties =
        {
            fontSize: isMobile ? "18px" : "24px",
            color: Colors.lightGrey,
            fontFamily: Styles.defaultFont,
            textAlign: "right",
            fontWeight: 600,
            margin: 0
        }

        let pinkStyle : CSSProperties =
        {
            color: Colors.tablesPink
        }

        let imageContainerStyle : CSSProperties = 
        {
            background: this.props.backgroundImages.length === 0 ? Colors.clear : Colors.darkerGrey,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            width: "100%",
            height: sectionHeight,
        }

        let imageStyle : CSSProperties = 
        {
            objectFit: "cover",
            width: "100%",
            height: "100%"
        }

        let dummyTextBoxStyle : CSSProperties = 
        {
            display: this.state.titleTextSizeIsSet ? "none" : "block",
            padding: padding,
            position: "absolute",
            visibility: "hidden",
            whiteSpace: "nowrap",
            background: "#00000000",
            top: 100
        }

        let dummyTitleStyle : CSSProperties =
        {
            fontSize: this.state.dummyTextSize + "px",
            textSizeAdjust: "200%",
            color: Colors.lightGrey,
            fontFamily: Styles.defaultFont,
            margin: 0
        }

        return (
            <div style={containerStyle}>
                <div style={imageContainerStyle}>
                    {this.props.backgroundImages.length === 0 ? "" : 
                        <SmoothTransitionImage images={this.props.backgroundImages} applyFilter={this.props.applyFilter}/> }
                </div>
                <div style={flexBoxContainer}>
                    <div style={flexBoxItem}>
                        <h1 style={titleStyle}><span style={pinkStyle}>Tables</span> Speed Dating</h1>
                        <h2 style={subtitleStyle}>find your next adventure...</h2>
                    </div>
                </div>
                <div style={dummyTextBoxStyle} ref={this.dummyTextContainer}>
                    <h1 style={dummyTitleStyle}><span style={pinkStyle}>Tables</span> Speed Dating</h1>
                </div>
            </div>
        )
    }

    componentDidMount(): void 
    {
        if(Util.isMobileDevice())
            this.setBestTitleTextSize();
    }

    async setBestTitleTextSize()
    {
        this.setState({ bestTitleTextSize: await this.findBestTitleTextSize(), titleTextSizeIsSet: true });
    }

    async findBestTitleTextSize()
    {
        //to fix text size too large bug on iPhone
        //we use a smaller fixed text size on mobile safari
        if (Util.isMobileSafari())
            return 32;

        if(this.dummyTextContainer.current == null)
        {
            return this.state.bestTitleTextSize;
        }

        let [screenWidth, screenHeight] = Util.getScreenSize();

        let maxFontSize = 100;
        for(let i = 1; i < maxFontSize; i++)
        {
            this.setState({ dummyTextSize: i });

            //allows renderer to update before measuring div
            await new Promise(r => setTimeout(r, 0));
            
            //measure the width of the div compared to screen width
            let divWidth = this.dummyTextContainer.current!.clientWidth;
            if (divWidth > screenWidth)
            {
                return this.state.dummyTextSize - 1;
            }
        }

        return this.state.bestTitleTextSize;
    }
}