import App from "./App";
import { parse, format } from 'date-fns';

export class Util 
{
    static getAbsoluteUrl(filename : string)
    {
        return process.env.PUBLIC_URL + '/' + filename;
    }

    static getImageUrl(filename : string)
    {
        return process.env.PUBLIC_URL + '/images/' + filename;
    }

    static copy(object : any)
    {
        return JSON.parse(JSON.stringify(object));
    }

    static getActiveAnchor()
    {
        if(App.instance?.anchors == null) return 0;

        for(let i = App.instance?.anchors.length; i >= 0; i--)
        {
            if(this.anchorIsAbovePageTop(i))
                return i;
        }

        //should never happen
        return 0;
    }

    static getAnchorY(anchorIndex : number)
    {
        return App.instance?.anchors[anchorIndex]?.current?.offsetTop;
    }

    static anchorIsAbovePageTop(anchorIndex : number)
    {
        let anchorY = this.getAnchorY(anchorIndex);
        if(anchorY === undefined) return false;

        return window.pageYOffset >= anchorY - 16;
    }

    static isMobileDevice()
    {
        return window.matchMedia("only screen and (max-width: 760px)").matches;
    }

    static getScreenSize()
    {
        return [document.documentElement.clientWidth, document.documentElement.clientHeight];
    }

    static calculatedScreenHeight = 0;
    static get initialScreenHeight() 
    {
        //measure screen height
        //we must do this only once, to prevent jumping when scrolling on mobile (as address bar gets hidden)
        if (this.calculatedScreenHeight == 0)
            this.calculatedScreenHeight = window.innerHeight;

        return this.calculatedScreenHeight;
    }

    static getContentOffset()
    {
        //less offset when we have a very small screen (e.g. iPhone 6S)
        //prevents tagline from being covered by the app store links
        if (Util.initialScreenHeight < 560)
            return -100;

        return -200;
    }

    static getNavbarHeight()
    {
        return 96;
    }

    static getFooterHeight()
    {
        return 128;
    }

    static getMobileOperatingSystem() 
    {
        //@ts-ignore
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        //@ts-ignore
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    static isIos()
    {
        return this.getMobileOperatingSystem() === "iOS";
    }

    static isFacebookApp() 
    {
        //@ts-ignore
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1 || (ua.indexOf("Instagram") > -1));
    }

    static formatEventDate(inputDate: string): string {
        // Parse the input date string into a Date object
        const parsedDate = this.parseDate(inputDate);
        
        // Format the date into the desired output format
        const formattedDate = format(parsedDate, "EEEE, do MMMM p").replace("AM", "am").replace("PM", "pm");

        // Remove the extra space before 'am' or 'pm'
        const finalFormattedDate = formattedDate.replace(/ (\bam|\bpm)$/, "$1");
        
        return finalFormattedDate;
    }

    static getDateDayOfMonthString(inputDate : string)
    {
        const parsedDate = this.parseDate(inputDate);

        const formattedDay = parsedDate.toLocaleDateString('en-US', { day: '2-digit' });

        return formattedDay;
    }

    static getDateMonthShortString(inputDate : string)
    {
        const parsedDate = this.parseDate(inputDate);

        const formattedMonth = parsedDate.toLocaleDateString('en-US', { month: 'short' }).toUpperCase();

        return formattedMonth;
    }

    static parseDate(date : string)
    {
        return parse(date, 'dd/MM/yyyy hh:mm aa', new Date());
    }
/*
    static formatDate(date: Date) : string 
    {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
      
        // Adding suffix to the day
        let suffix = 'th';
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
          suffix = 'st';
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
          suffix = 'nd';
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
          suffix = 'rd';
        }
      
        // Formatting hours in 12-hour format with AM/PM
        const hours = date.getHours();
        const isPM = hours >= 12;
        const formattedHour = ((hours + 11) % 12 + 1); // Converts 0-23 hour to 1-12 hour format
        const amPm = isPM ? 'pm' : 'am';
      
        return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}, ${formattedHour}${amPm}`;
    }
*/
    static getLocationNameFormatted(location : string)
    {
        return location
            //split the string by hyphens
            .split('-')
            //capitalize the first letter of each word and 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            //join them back together with a space
            .join(' ');
    }

    static isMobileSafari() 
    {
        //@ts-ignore
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (userAgent.includes('FBIOS')) {
            //iOS Facebook App Browser detected
            return true;
        }
    
        // Check for iOS in any device (iPhone, iPod, iPad)
        //@ts-ignore
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Check if it is Safari
            return /Safari/.test(userAgent) && !/CriOS/.test(userAgent);
        }

        return false;
    }
}