import React, { CSSProperties } from "react";
import App from "./App";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";

export class ScreenshotsSection extends React.Component
{
    render(): React.ReactNode {

        let isMobile = Util.isMobileDevice();

        let padding = isMobile ? 16 : 32;

        let offsetY = Util.getContentOffset();

        let showStaticImagesOnly = Util.isIos() || Util.isFacebookApp();

        let containerStyle : CSSProperties =
        {
            width: "100%",
            boxSizing: "border-box",
            padding: `0 ${padding}px`
        }

        let flexBoxContainer = Styles.flexBoxContainer;
        flexBoxContainer.flexDirection = "row";

        let flexBoxItem = Styles.flexBoxItem;
        flexBoxItem.transform = `translate(0px, ${offsetY}px)`;
        flexBoxItem.maxWidth = 1000;

        let imageStyle : CSSProperties =
        {
            width: "33%",
            margin: 0,
            padding: "0 16px",
            boxSizing: "border-box",
            cursor: "zoom-in"
        }

        let animationsContent = 
        <div style={flexBoxItem}>
            <video style={imageStyle} src={Util.getImageUrl("anim-purchase-3000.webm")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
            <video style={imageStyle} src={Util.getImageUrl("anim-match-3000.webm#t=0.2")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
            <video style={imageStyle} src={Util.getImageUrl("anim-messaging-3000.webm")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
        </div>

        let imagesContent = 
        <div style={flexBoxItem}>
            <img style={imageStyle} src={Util.getImageUrl("frame01-purchase.png")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
            <img style={imageStyle} src={Util.getImageUrl("frame02-match.png")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
            <img style={imageStyle} src={Util.getImageUrl("frame01-messaging.png")} onClick={(e) => this.showImageFullScreen(e.target as HTMLImageElement)}/>
        </div>

        return (
            <div style={containerStyle}>
                <div style={flexBoxContainer}>
                    {showStaticImagesOnly ? imagesContent : animationsContent}
                </div>
            </div>
        )
    }

    showImageFullScreen(sender : HTMLImageElement)
    {
        App.instance.setState({ selectedImageUrl: sender.src });
    }


}