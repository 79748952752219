export class Faq
{
    static getFaq()
    {
        return [
            [
                "What happens when I arrive at one of your dating events?",
                "Once you arrive at the venue, you will be greeted by one of our friendly hosts. You then will be asked to wait at the bar, or assigned a table to sit at."
            ],
            [
                "How does the event work?",
                "Once all the attendees are checked in and have found their seats, the host will ring a bell to let dating commence! When you hear the bell again 3 minutes later, you rotate to the next table. Keep rotating until you've met all your potential matches, then feel free to mingle afterwards."
            ],
            [
                "How do I book a ticket?",
                "You can book a ticket to one of our events through the Tables app or Eventbrite. We accept card payments, PayPal and Apple Pay."
            ],
            [
                "How do I connect with people after the event?",
                "You can post your choices easily and efficiently through our mobile app. Look through your potential matches' profiles and decide who you want to connect with. You will be notified of any matches through push notifications to your device."
            ],
            [
                "What if I forget peoples' names after the event?",
                "After the event, you will find the profiles of everyone you met, in the app. You'll be able to see their first name, age and any photos they have uploaded."
            ],
            [
                "What if not enough people turn up to the event?",
                "If the numbers are too low for the event, the event will be cancelled. We will offer you a refund, or you can transfer to another event."
            ],
            [
                "Can I bring a Plus One?",
                "Some of our events allow you to get a Plus One ticket when you purchase through the app, which allows access to the event for a friend. Instructions will be included in your booking confirmation email."
            ],
            [
                "Can I go to a Tables event without the mobile app?",
                "Unfortunately not, we don't collect any results via paper."
            ],
            [
                "What happens if my phone runs out of battery before the event?",
                "Not to worry, you can post your results at any time. There is no time limit."
            ]
        ]
    }
}