import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { EventbriteEvent, EventbriteEventsResponse } from "./EventbriteModels";
import { Button } from "@mui/material";
import { TablesEvent } from "./TablesModels";

interface EventsCardProps
{
    location : string,
    offsetY : number
}

interface EventsCardState
{
    events: TablesEvent[],
    isLoading : boolean,
    ellipsisCount : number
}

export class EventsCard extends React.Component<EventsCardProps, EventsCardState>
{
    state : EventsCardState = { events: [], isLoading: true, ellipsisCount: 3 }

    thingyInterval : NodeJS.Timer | undefined = undefined;

    render()
    {
        let isMobile = Util.isMobileDevice();

        let imageSize = isMobile ? 172 : 192;

        let spacing = isMobile ? 16 : 32;

        let headingStyle : CSSProperties =
        {
            margin: `${spacing}px ${spacing}px ${spacing*0.5}px ${spacing}px`
        }

        let subtitleStyle : CSSProperties =
        {
            margin: `0 ${spacing}px ${spacing}px ${spacing}px`
        }

        let linkedSubtitleStyle = Util.copy(subtitleStyle) as CSSProperties;
        linkedSubtitleStyle.textDecoration = "underline";

        let cardTextContentSpacing = isMobile ? "4px" : "12px";

        let timeStyle : CSSProperties =
        {
            margin: `${cardTextContentSpacing} 0 0 0`,
            fontSize: "16px"
        }

        let descriptionStyle : CSSProperties =
        {
            margin: `${cardTextContentSpacing} 0 0 0`,
            fontSize: "16px",
        }

        let eventTitleStyle : CSSProperties =
        {
            margin: "12px 0 0 0",
            fontSize: isMobile ? "18px" : "24px"
        }

        let eventItemStyle : CSSProperties =
        {
            background: Colors.darkGrey,
            padding: 0,
            margin: isMobile ? "16px 0 0 0" : "0 16px 16px 16px",
            display: "flex",
            alignItems: "flex-start",
        }

        let getImageContainerStyle = (imageUrl : string) =>
        {
            let imageContainerStyle : CSSProperties =
            {
                position: "relative",
                flexShrink: 0,
                background: Colors.veryDarkGrey,
                width: imageSize,
                height: imageSize,
                overflow: "hidden",
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "cover",                     
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center"
            }

            return imageContainerStyle;
        } 

        let infoContainerStyle : CSSProperties =
        {
            flexGrow: 1,
            padding: "0 16px",
            position: "relative",
            height: imageSize,
            overflow: "hidden"
        }

        let buttonStyle : CSSProperties =
        {
            background: Colors.tablesBlue,
            color: Colors.veryDarkGrey,
            padding: 8,
            margin: 0,
            fontFamily: Styles.defaultFont,
            fontWeight: 700,
            borderRadius: 0,
            position: "absolute",
            right: 0,
            bottom: 0
        }

        let loadingStyle : CSSProperties =
        {
            fontWeight: 700,
            marginLeft: spacing,
            marginBottom: spacing,
            color: Colors.tablesBlue
        }

        let cardStyle : CSSProperties =
        {
            position: "relative",
            fontSize: "17px",
            textAlign: "left",
            color: Colors.lightGrey,
            fontFamily: "Open Sans, sans-serif",
            padding: 0,
            marginBottom: 32,
            transform: `translate(0px, ${this.props.offsetY}px)`,
            border: Styles.border,
            borderRadius: Styles.borderRadius,
            background: Colors.darkerGrey,
            maxWidth: 1000
        }

        let dateBadgeStyle : CSSProperties =
        {
            background: Colors.veryDarkGrey,
            color: Colors.lightGrey,
            position: "absolute",
            top: 16,
            left: 16,
            width: 48
        }

        let locationNameFormattedWithBrackets = this.props.location === "all" ? "" : `(${Util.getLocationNameFormatted(this.props.location)})`;

        let locationNameFormattedWithoutBrackets = locationNameFormattedWithBrackets.replace("(", "").replace(")", "");

        let locationNameSubtitleInsert = this.props.location === "all" ? "Cheshire and The North West" : locationNameFormattedWithoutBrackets;

        return (
            <div style={cardStyle}>
                <h1 style={headingStyle}>Upcoming Events {locationNameFormattedWithBrackets}</h1>
                <p style={subtitleStyle}>Explore speed dating events in {locationNameSubtitleInsert}, hosted by <b>Tables Speed Dating.</b> Discover a seamless way to meet singles, engage in lively conversations, and possibly find your perfect match in a comfortable, relaxed setting.</p>
                {this.props.location === "all" ? <p style={subtitleStyle}>We are now hosting events in Altrincham, Chester, Nantwich, West Kirby and Alderley Edge.</p> : ""}
                <p style={subtitleStyle}>Book early to get <b>25% OFF!</b></p>
                <a href="/speed-dating-events"><p style={linkedSubtitleStyle}>View All Events</p></a>
                {this.state.events.map((event, key) => 
                <a key={key} href={event.eventBriteLink}>
                    <div key={key} style={eventItemStyle}>
                        <div style={getImageContainerStyle(event.eventImage)}>
                            <div style={dateBadgeStyle}>
                                <div style={{ background: Colors.darkGrey, textAlign: "center", paddingTop: 4, fontSize: "12pt" }}>{Util.getDateMonthShortString(event.dateFormatted)}</div>
                                <div style={{ textAlign: "center", fontWeight: "700", paddingBottom: 4, fontSize: "14pt" }}>{Util.getDateDayOfMonthString(event.dateFormatted)}</div>
                            </div>
                        </div>
                        <div style={infoContainerStyle}>
                            <h1 style={eventTitleStyle}>{this.getEventName(event)}</h1>
                            <p style={timeStyle}>{Util.formatEventDate(event.dateFormatted)}</p>
                            <p style={descriptionStyle}>{isMobile ? "" : this.getEventDescription(event)}</p>
                            <Button href={event.eventBriteLink} style={buttonStyle}>GET TICKETS</Button>
                        </div>
                    </div>
                </a>)}
                { this.state.isLoading ? <p style={loadingStyle}>LOADING{this.getLoadingEllipsis()}</p> : "" }
            </div>
        )
    }

    componentDidMount(): void 
    {
        this.getUpcomingEvents();

        if (this.thingyInterval)
            clearInterval(this.thingyInterval)

        this.thingyInterval = setInterval(() => { 
            
            let newEllipsisCount = this.state.ellipsisCount + 1;
            if (newEllipsisCount > 3)
                newEllipsisCount = 0;

            this.setState({ ellipsisCount: newEllipsisCount });
        }, 1000);
    }

    async getUpcomingEvents()
    {
        let ep = "getEvents.php";

        let response = await fetch(`https://tablesapp.co.uk/backend/${ep}?location=${this.props.location}`);

        let upcomingEventsResponse = await response.json() as TablesEvent[];

        console.log(upcomingEventsResponse)

        if (upcomingEventsResponse == null)
        {
            console.log("Error: events failed to load");
            return;
        }

        this.setState({ events: upcomingEventsResponse, isLoading: false });
    }

    getEventName(event : TablesEvent)
    {
        return `Speed Dating // ${event.minAge}-${event.maxAge} // ${event.town}`;
    }

    getEventDescription(event : TablesEvent)
    {
        return event.eventDescription
            .replace(/(<([^>]+)>)/gi, "") /* strips html tags */
            .split(",")[0];
    }

    getLoadingEllipsis()
    {
        switch(this.state.ellipsisCount)
        {
            case 0:
                return "";
            case 1: 
                return ".";
            case 2:
                return "..";
            case 3:
                return "...";
        }
    }

/*
    async getUpcomingEvents()
    {
        let ep = "getEventbriteEvents.php";

        let response = await fetch(`https://tablesapp.co.uk/backend/${ep}`);

        let upcomingEventsResponse = await response.json() as EventbriteEventsResponse;

        this.setState({ events: upcomingEventsResponse.events });
    }

    getEventDate(eventStartTime : string)
    {
        //converts it to a date to get the local time
        var date = new Date(eventStartTime); 

        //format it in the format Tuesday 26th September 2023, 7pm
        return Util.formatDate(date);// date.toLocaleDateString() 
    }
    */
}