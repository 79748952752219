import React, { CSSProperties, createRef } from "react";
import { Colors } from "../Colors";
import { Util } from "../Util";
import { ImageViewer } from "../ImageViewer";
import { StaticBackground } from "../StaticBackground";
import { NavBar } from "../NavBar";
import { HomeSection } from "../HomeSection";
import { AboutSection } from "../AboutSection";
import { ScreenshotsSection } from "../ScreenshotsSection";
import { FaqSection } from "../FaqSection";
import { Footer } from "../Footer";
import { EventsCard } from "../EventsCard";
import { VectorBanner } from "../VectorBanner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface LocationPageProps
{
    selectedImageUrl : string,
    location : string
}

export const LocationPage = (props : LocationPageProps) => {
    const location = useLocation(); 

    const fullUrl = `https://tablesapp.co.uk${location.pathname}`;

    const showHomeSection = true; //shows the Tables Speed Dating title text for the splash screen top section of the page
    
    let offsetY = showHomeSection ? Util.getContentOffset() : 0;

    let pageStyle : CSSProperties = 
    {
        width: "100%",
        overflowX: "hidden",
        fontFamily: 'Open Sans, sans-serif',
        colorScheme: "dark",
        WebkitTapHighlightColor: Colors.clear, //disables highlight when tapping things on Chrome & Opera on Android
    }

    let marginTop = Util.getNavbarHeight() + 32;

    let flexBoxContainer : CSSProperties =
    {
        display: "flex",          /* establish flex container */
        flexDirection: "column",  /* make main axis vertical */
        //justifyContent: "center", /* center items vertically, in this case */
        alignItems: "center",     /* center items horizontally, in this case */
        minHeight: showHomeSection ? 0 : `calc(100vh - ${marginTop + Util.getFooterHeight() + 1}px)`, //keeps the footer at the bottom on a tall screen
        marginTop: showHomeSection ? 0 : marginTop,
        paddingBottom: showHomeSection ? 32 : 0 //ensures the tables logo at the bottom is vertically centered in its space
    }

    let flexBoxItem : CSSProperties =
    {
        margin: 0,
        //textAlign: "center"       /* will center text in <p>, which is not a flex item */
    }

    let locationReadable = Util.getLocationNameFormatted(props.location);

    let title = `Tables Speed Dating - ${locationReadable} Events`;

    let description = `A fresh and fun way to meet new people. Discover meaningful connections at Tables Speed Dating events in ${locationReadable}`;

    return (
        <div style={pageStyle}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={fullUrl}></link>
            </Helmet>
            <ImageViewer imageUrl={props.selectedImageUrl}/>
            <StaticBackground backgroundImages={[ Util.getImageUrl("bg-image3.jpg"), Util.getImageUrl("bg-image4.jpg") ]}/>
            <NavBar location={props.location}/>
            {showHomeSection ? <HomeSection backgroundImages={[ ]} applyFilter={false}/> : ""}
            <div style={flexBoxContainer}>
                <div style={flexBoxItem}>
                    <EventsCard offsetY={offsetY} location={props.location}/>
                </div>
            </div>
            <Footer showTablesLogo={showHomeSection}/>
        </div>
    );
}