import { Helmet } from "react-helmet";

function getMobileOperatingSystem() {
    //@ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    //Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    //iOS detection from: http://stackoverflow.com/a/9039885/177710
    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

function redirectToAppStoreOrGooglePlayStore()
{
    let os = getMobileOperatingSystem();

    //redirect to app store or google play
    let url = os === "iOS" ? "https://apps.apple.com/gb/app/tables-dating/id1616319130" : "https://play.google.com/store/apps/details?id=com.glynbatemanit.tables";
    window.location.replace(url);

    //after 3 seconds, redirect to homepage (avoids black screen hanging around when navigating back to browser e.g. facebook mobile browser)
    setTimeout(() => window.location.replace("https://tablesapp.co.uk/"), 3000);
}

export function DownloadRedirect() 
{
    redirectToAppStoreOrGooglePlayStore();

    const fullUrl = `https://tablesapp.co.uk/download`;

    const title = "Tables App Download";

    const description = `Download the Tables Speed Dating app to discover relaxed and engaging speed dating events in your area`;

    return <div>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={fullUrl}></link>
        </Helmet>
    </div>;
}