import React, { CSSProperties, createRef } from "react";
import { Colors } from "../Colors";
import { Util } from "../Util";
import { ImageViewer } from "../ImageViewer";
import { StaticBackground } from "../StaticBackground";
import { NavBar } from "../NavBar";
import { HomeSection } from "../HomeSection";
import { AboutSection } from "../AboutSection";
import { ScreenshotsSection } from "../ScreenshotsSection";
import { FaqSection } from "../FaqSection";
import { Footer } from "../Footer";
import { EventsCard } from "../EventsCard";
import { VectorBanner } from "../VectorBanner";
import Carousel from "../Carousel";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";

export class TestPage extends React.Component
{
    render()
    {
        let isMobile = Util.isMobileDevice();

        let offsetY = Util.getContentOffset();

        let pageStyle : CSSProperties = 
        {
            width: "100%",
            overflowX: "hidden",
            fontFamily: 'Open Sans, sans-serif',
            colorScheme: "dark",
            WebkitTapHighlightColor: Colors.clear, //disables highlight when tapping things on Chrome & Opera on Android
        }

        return (
            <div style={pageStyle}>
                <Helmet>
                    <title>Tables Speed Dating</title>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                {/*<Carousel images={["https://rob-cdn.ams3.digitaloceanspaces.com/website/pubs/t00191-drumandmonkey-alderleyedge/2022/drumnew.jpg"]}/>*/}
                <Button href="Clickme">ClickMe</Button>
            </div>
        );
    }
}