import React, { CSSProperties } from "react";
import App from "./App";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { VectorBanner } from "./VectorBanner";
import { EventsCard } from "./EventsCard";

const appStoreLinksHeight = 48;
const appStoreLinksRatio = 6.6;

interface AboutSectionProps
{
    aboutAnchor : React.ReactNode;
}

export class AboutSection extends React.Component<AboutSectionProps>
{
    render(): React.ReactNode {

        let isMobile = Util.isMobileDevice();

        let spacing = isMobile ? 16 : 32;

        let offsetY = Util.getContentOffset();

        let [screenWidth, screenHeight] = Util.getScreenSize();

        let containerStyle : CSSProperties =
        {
            width: "100%",
            boxSizing: "border-box"
        }

        let flexBoxContainer : CSSProperties =
        {
            display: "flex",          /* establish flex container */
            flexDirection: "column",  /* make main axis vertical */
            justifyContent: "center", /* center items vertically, in this case */
            alignItems: "center",     /* center items horizontally, in this case */
            height: "100%",
            paddingBottom: spacing
        }

        let flexBoxItem : CSSProperties =
        {
            margin: 0,
            textAlign: "center"       /* will center text in <p>, which is not a flex item */
        }

        let listStyle : CSSProperties =
        {
            margin: spacing,
            fontWeight: "bold",
            fontSize: "20px",
            paddingLeft: isMobile ? "24px" : "40px"
        }

        let nonBoldStyle : CSSProperties =
        {
            fontWeight: "normal",
            fontSize: "17px"
        }

        let headingStyle : CSSProperties =
        {
            margin: `${spacing}px ${spacing}px 0 ${spacing}px`
        }

        let blueStyle : CSSProperties =
        {
            color: Colors.tablesBlue
        }

        let pinkStyle : CSSProperties =
        {
            color: Colors.tablesPink
        }

        let cardStyle : CSSProperties =
        {
            position: "relative",
            fontSize: "17px",
            textAlign: "left",
            color: Colors.lightGrey,
            fontFamily: "Open Sans, sans-serif",
            padding: 0,
            transform: `translate(0px, ${offsetY}px)`,
            border: Styles.border,
            borderRadius: Styles.borderRadius,
            background: Colors.darkerGrey,
            maxWidth: 1000
        }

        let bgContainerStyle : CSSProperties =
        {
            position: "absolute",
            left: 0,
            right: 0
        }

        let appStoreFlexStyle : CSSProperties =
        {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "stretch",
            width: isMobile ? "calc(100% - 12px)" : "100%",
            fontSize: 0,
            maxWidth: 1064,
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative"
        }

        let appStoreLinksContainerStyle : CSSProperties =
        {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "flex-end",
            position: "absolute",
            right: 0,
            bottom: 0,
            height: appStoreLinksHeight,
            width: appStoreLinksHeight * appStoreLinksRatio,
            transform: `translate(0px, ${offsetY - 8}px)`
        }

        let qrCodeContainer = Util.copy(appStoreLinksContainerStyle);
        qrCodeContainer.width = appStoreLinksHeight;
        qrCodeContainer.justifyContent = "flex-start";
        qrCodeContainer.right = "auto";
        qrCodeContainer.left = 0;

        let appStoreLinkStyle : CSSProperties =
        {
            flex: 1,
            boxSizing: "border-box",
            objectFit: "contain",
            cursor: "pointer"
        }

        let googlePlayLinkStyle = Util.copy(appStoreLinkStyle);
        googlePlayLinkStyle.marginLeft = 4;

        let qrToggleStyle = Util.copy(appStoreLinkStyle);
        qrToggleStyle.padding = 8;
        qrToggleStyle.marginRight = 8;
        qrToggleStyle.opacity = 0.8;

        let enoughSpaceForQrIcon = screenWidth > 400;

        let leftQrCodeIcon = enoughSpaceForQrIcon && isMobile ? <img style={qrToggleStyle} onClick={() => this.qrTap()} src={Util.getImageUrl("icon-qrcode-scan.svg")}></img> : "";

        let rightQrCodeIcon = enoughSpaceForQrIcon && !isMobile ? <img style={qrToggleStyle} onClick={() => this.qrTap()} src={Util.getImageUrl("icon-qrcode-scan.svg")}></img> : "";

        return (
            <div style={containerStyle}>
                <div style={bgContainerStyle}>
                    <VectorBanner/>
                </div>
                <div style={flexBoxContainer}>
                    <div style={flexBoxItem}>
                        <div style={appStoreFlexStyle}>
                            <div style={qrCodeContainer}>
                                {leftQrCodeIcon}
                            </div>
                            <div style={appStoreLinksContainerStyle}>
                                {rightQrCodeIcon}
                                <img style={appStoreLinkStyle} onClick={() => window.location.href = "https://apps.apple.com/gb/app/tables-dating/id1616319130"} src={Util.getImageUrl("icon-app-store.svg")}></img>
                                <img style={googlePlayLinkStyle} onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.glynbatemanit.tables"} src={Util.getImageUrl("icon-google-play.png")}></img>
                            </div>
                        </div>
                        <EventsCard offsetY={offsetY} location="all"/>
                        {this.props.aboutAnchor}
                        <div style={cardStyle}>
                            <h1 style={headingStyle}>How does it work?</h1>
                            <p style={{ marginLeft: spacing, marginRight: spacing}}>Navigating the dating scene has never been easier! Experience dynamic face-to-face dates at our speed dating events, followed by an intuitive mobile app to help you choose your perfect match.</p>
                            <ol style={listStyle}>
                                <li>
                                    <p><span style={blueStyle}>Book tickets</span> to one of our dating events</p>
                                    <p style={nonBoldStyle}>Find a speed dating event near you and <a href="#1" style={{ textDecoration: "underline" }}>book a ticket</a>. At the event you'll have a chance to meet all your potential matches and have a short conversation with each one.</p>
                                </li>
                                <li>
                                    <p><span style={blueStyle}>Post results</span> through the app</p>
                                    <p style={nonBoldStyle}>In traditional speed dating, you record your date preferences on paper for the host to connect you afterward. Our mobile app modernises this process by letting you submit your choices from home, complete with profile pictures and prompts to help you remember each date clearly.</p>
                                </li>
                                <li>
                                    <p> If you and another user both indicate interest, <span style={pinkStyle}>it's a match!</span></p>
                                    <p style={nonBoldStyle}>The app will notify you of any matches the second we get a positive result from you and another. Becoming a match allows you to carry on where you left off using the app's built-in chat system.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    qrTap()
    {
        App.instance.setState({ selectedImageUrl: Util.getImageUrl("qr-download.png") })
    }
}