import { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Util } from "./Util";
import React from "react";
import SmoothTransitionImage from "./SmoothTransitionImage";

interface StaticBackgroundProps
{
    backgroundImages : string[]
}

export function StaticBackground(props : StaticBackgroundProps)
{
    let containerStyle : CSSProperties =
    {
        width: "100%",
        height: "100vh",
        position: "fixed",
        background: Colors.darkGrey,
        zIndex: -1
    }

    let bgImageStyle : CSSProperties =
    {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        display: "block"
    }

    return (
        <div style={containerStyle}>
            <SmoothTransitionImage images={props.backgroundImages} applyFilter={true}/>
        </div>
    )
}