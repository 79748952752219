import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";
import { Util } from "./Util";
import { EventbriteEvent, EventbriteEventsResponse } from "./EventbriteModels";
import { Button } from "@mui/material";

interface ErrorCardProps
{
    offsetY : number
}

export class ErrorCard extends React.Component<ErrorCardProps>
{
    render()
    {
        let isMobile = Util.isMobileDevice();

        let imageSize = isMobile ? 172 : 192;

        let spacing = isMobile ? 16 : 32;

        let headingStyle : CSSProperties =
        {
            margin: `${spacing}px ${spacing}px ${spacing*0.5}px ${spacing}px`
        }

        let subtitleStyle : CSSProperties =
        {
            margin: `0 ${spacing}px ${spacing}px ${spacing}px`
        }

        let linkedSubtitleStyle = Util.copy(subtitleStyle) as CSSProperties;
        linkedSubtitleStyle.textDecoration = "underline";

        let linkStyleBlue : CSSProperties =
        {
            display: "inline-block",
            background: Colors.tablesBlue,
            color: Colors.veryDarkGrey,
            fontWeight: "bolder",
            margin: `0 0 ${spacing * 0.5}px ${spacing * 0.5}px`,
            padding: 16
        }

        let linkContainerStyle : CSSProperties =
        {
            margin: `0 0 ${spacing * 0.5}px ${spacing * 0.5}px`
        }

        //let linkedSubtitleStyle = Util.copy(subtitleStyle) as CSSProperties;
        //linkedSubtitleStyle.textDecoration = "underline";

        let cardTextContentSpacing = isMobile ? "4px" : "12px";

        let timeStyle : CSSProperties =
        {
            margin: `${cardTextContentSpacing} 0 0 0`,
            fontSize: "16px"
        }

        let descriptionStyle : CSSProperties =
        {
            margin: `${cardTextContentSpacing} 0 0 0`,
            fontSize: "16px",
        }

        let eventTitleStyle : CSSProperties =
        {
            margin: "12px 0 0 0",
            fontSize: isMobile ? "18px" : "24px"
        }

        let eventItemStyle : CSSProperties =
        {
            background: Colors.darkGrey,
            padding: 0,
            margin: isMobile ? "16px 0 0 0" : "0 16px 16px 16px",
            display: "flex",
            alignItems: "flex-start",
        }

        let getImageContainerStyle = (imageUrl : string) =>
        {
            let imageContainerStyle : CSSProperties =
            {
                flexShrink: 0,
                background: Colors.tablesBlue,
                width: imageSize,
                height: imageSize,
                overflow: "hidden",
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "cover",                     
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center"
            }

            return imageContainerStyle;
        } 

        let infoContainerStyle : CSSProperties =
        {
            flexGrow: 1,
            padding: "0 16px",
            position: "relative",
            height: imageSize,
            overflow: "hidden"
        }

        let buttonStyle : CSSProperties =
        {
            background: Colors.tablesBlue,
            color: Colors.veryDarkGrey,
            padding: 8,
            margin: 0,
            fontFamily: Styles.defaultFont,
            fontWeight: 700,
            borderRadius: 0,
            position: "absolute",
            right: 0,
            bottom: 0
        }

        let loadingStyle : CSSProperties =
        {
            fontWeight: 700,
            textAlign: "center",
            color: Colors.tablesBlue
        }

        let cardStyle : CSSProperties =
        {
            position: "relative",
            fontSize: "17px",
            textAlign: "left",
            color: Colors.lightGrey,
            fontFamily: "Open Sans, sans-serif",
            padding: 0,
            marginBottom: 32,
            transform: `translate(0px, ${this.props.offsetY}px)`,
            border: Styles.border,
            borderRadius: Styles.borderRadius,
            background: Colors.darkerGrey,
            maxWidth: 1000
        }

        return (
            <div style={cardStyle}>
                <h1 style={headingStyle}>404 - Page Not Found</h1>
                <p style={subtitleStyle}>We couldn't find the page you were looking for. You can navigate back to the homepage using the link below: </p>
                <a href="/"><p style={linkedSubtitleStyle}>Back To Homepage</p></a>
            </div>
        )
    }
}