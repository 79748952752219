import { CSSProperties } from "react";
import { Colors } from "./Colors";

export class Styles
{
    static get border()
    {
        return "1px solid " + Colors.darkGrey;
    }

    static get borderRadius()
    {
        return 0;
    }

    static get flexBoxContainer()
    {
        let flexBoxContainer : CSSProperties =
        {
            display: "flex",          /* establish flex container */
            flexDirection: "column",  /* make main axis vertical */
            justifyContent: "center", /* center items vertically, in this case */
            alignItems: "center",     /* center items horizontally, in this case */
            height: "100%"
        }
        
        return flexBoxContainer;
    }

    static get flexBoxItem()
    {
        let flexBoxItem : CSSProperties =
        {
            textAlign: "center"       /* will center text in <p>, which is not a flex item */
        }
        
        return flexBoxItem;
    }

    static get defaultFont()
    {
        return 'Open Sans, sans-serif';
    }
}