import React, { CSSProperties } from "react";
import { Colors } from "./Colors";
import { Styles } from "./Styles";

const width = 2000;
const height = 128;
const scale = 0.2;
const imageCount = 500;

export class VectorBanner extends React.Component
{
    render(): React.ReactNode {

        let containerStyle : CSSProperties =
        {
            width: "100%",
            height: height,
            background: Colors.veryDarkGrey,
            borderTop: Styles.border,
            borderBottom: Styles.border,
            overflowX: "hidden"
        }

        return (
            <div style={containerStyle}>
                <svg style={{display: "block"}}
                    viewBox={`0 0 ${width} ${height}`} max-width="100%" width="auto" height={height}>

                <defs>
                    <g id="heartLight">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={Colors.lightGrey}><title>heart</title><path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" /></svg>
                    </g>
                    <g id="heartDark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={Colors.midGrey}><title>heart</title><path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" /></svg>
                    </g>
                </defs>

                {/* A group to hold the created heart references. Not necessary, but keeps things tidy. */}
                <g id="markers">
                </g>
                </svg>
            </div>
        )
    }

    componentDidMount() 
    {
        this.drawVectors();
    }

    drawVectors()
    {
        const positions = this.getVectorPositions(width, height);

        let pixelWidth = document.documentElement.clientWidth * window.devicePixelRatio;
        let imageScale =  1920 * scale / pixelWidth;

        const svgNS = "http://www.w3.org/2000/svg";
        const xlinkNS = "http://www.w3.org/1999/xlink";

        for (let i = 0; i < positions.length; i++) 
        {
            // Create an SVG <use> element
            let use = document.createElementNS(svgNS, "use");
            //point it at our heart vector
            use.setAttributeNS(xlinkNS, "href", positions[i][3].toString());
            //set position
            use.setAttribute("transform", `translate(${positions[i][0]}, ${positions[i][1]}) scale(${imageScale} ${imageScale}) rotate(${positions[i][2]} 0 0)`);
            //add it to the "markers" group
            document.getElementById("markers")?.appendChild(use);
        }
    }

    getVectorPositions(maxX : number, maxY : number)
    {
        let positions = [];
        for(let i = 0; i < imageCount; i++)
        {
            let x = Math.random() * maxX - 100;
            let y = Math.random() * maxY - 100;
            let angle = Math.random() * 360;
            let type = Math.random() < 0.5 ? "#heartLight" : "#heartDark"; 

            positions.push([x, y, angle, type])
        }

        return positions;
    }
}