import React, { useState, useEffect, CSSProperties } from 'react';
import { Util } from './Util';
import { Colors } from './Colors';

interface SmoothTransitionImageProps 
{
    images : string[],
    applyFilter : boolean
}

const SmoothTransitionImage = (props : SmoothTransitionImageProps) => 
{
    const [fadeInIndex, setFadeInIndex] = useState(0); // Tracks the image that fades in
    const [fadeOutIndex, setFadeOutIndex] = useState(-1); // Tracks the image that fades out
    const [fade, setFade] = useState(false); // Controls when to start fading

    // Initiate fade in and out
    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOutIndex(fadeInIndex); // Set the current image to fade out
            setFadeInIndex((fadeInIndex + 1) % props.images.length); // Update to new image to fade in
            setFade(true); // Start the fade transition
        }, 10000); 

        return () => clearInterval(interval);
    }, [fadeInIndex, props.images.length]);

    // Inline styles for the components
    const containerStyle: React.CSSProperties = {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    const baseImageStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'opacity 2s ease-in-out', // Make transition longer to see the effect
    };

    const imageStyle = (index: number): React.CSSProperties => {
        return {
            ...baseImageStyle,
            opacity: index === fadeInIndex ? (fade ? 1 : 0) : (fade && index === fadeOutIndex ? 0 : 1),
            zIndex: index === fadeInIndex ? 1 : 0,
        };
    };

    let imageFilterStyle : CSSProperties = 
    {
        display: props.applyFilter ? "block" : "none",
        background: Colors.colorWithAlpha("#000000", 0.5),
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100
    }

    let imageElements = 
        props.images.length === 0 ? "" :
        props.images.length === 1 ?
        <img style={baseImageStyle} src={props.images[0]}/> :
        <div>
            {props.images.map((src, index) => (
                <img
                    key={index}
                    src={src}
                    style={imageStyle(index)}
                    alt={`Image ${index}`}
                />
            ))}
        </div>

    return (
        <div style={containerStyle}>
            {imageElements}
            
            <div style={imageFilterStyle}></div>
        </div>
    );
};

export default SmoothTransitionImage;
