import React, { createRef, CSSProperties } from 'react';
import logo from './logo.svg';
import './App.css';
import { Colors } from './Colors';
import { Util } from './Util';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { LocationPage } from './pages/LocationPage';
import { TestPage } from './pages/TestPage';
import { DownloadRedirect } from './pages/DownloadRedirect';
import { LocationHubPage } from './pages/LocationHubPage';
import { ErrorPage } from './pages/ErrorPage';

const locations = [
    "alderley-edge",
    "altrincham",
    "birkenhead",
    "bowdon",
    "cheadle",
    "chorlton",
    "cheshire",
    "chester",
    "crewe",
    "hale",
    "irlam",
    "knutsford",
    "macclesfield",
    "manchester",
    "nantwich",
    "salford-quays",
    "sale",
    "shrewsbury",
    "shropshire",
    "stoke",
    "stockport",
    "stretford",
    "stafford",
    "the-north-west",
    "the-west-midlands",
    "the-wirral",
    "telford",
    "timperley",
    "trafford",
    "urmston",
    "west-kirby",
    "wilmslow",
    "wythenshawe"
];

interface AppState
{
    selectedImageUrl : string
}

class App extends React.Component 
{
    static instance : App;

    state : AppState = { selectedImageUrl : "" }

    anchors = [ createRef<HTMLAnchorElement>(), createRef<HTMLAnchorElement>(), createRef<HTMLAnchorElement>(), createRef<HTMLAnchorElement>(), createRef<HTMLAnchorElement>() ];

    isLandscape = false;

    render(): React.ReactNode 
    {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage selectedImageUrl={this.state.selectedImageUrl} anchors={this.anchors}/>} />
                    <Route path="/speed-dating-events" element={<LocationHubPage selectedImageUrl={this.state.selectedImageUrl} locations={locations}/>} />
                    {locations.map((location, key) => 
                        <Route key={key} path={`/speed-dating-events/${location}`} element={<LocationPage selectedImageUrl={this.state.selectedImageUrl} location={location}/>} />
                    )}
                    <Route path="/test" element={<TestPage/>} />
                    <Route path="/download" element={<DownloadRedirect/>} />
                    <Route path="*" element={<ErrorPage selectedImageUrl={this.state.selectedImageUrl} />} />
                </Routes>
            </BrowserRouter>
        );
    }

    componentDidMount(): void 
    {
        App.instance = this;

        //re-render when device is rotated (updates text sizes etc.)
        window.addEventListener("resize", () =>
        {
            let [currentWidth, currentHeight] = Util.getScreenSize();
            let newIsLandscape = currentWidth > currentHeight;
            if (this.isLandscape != newIsLandscape)
            {
                //force screen height to be recalculated
                Util.calculatedScreenHeight = 0;

                this.forceUpdate();

                this.isLandscape = newIsLandscape;
            }
        });
    }
}

export default App;
